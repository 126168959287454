import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { pusherEvents } from "@/enums/pusherEvents";
import { Driver } from "@/interfaces/driver";
import { DriversTableMetadata } from "@/metadata/drivers";
import { driverService } from "@/services/driver.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  BooleanCheck,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./DriversList.template.vue";

const namespace = "DriversModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(driverService)
export default class DriversComponent extends Vue {
  // Table Headers
  public headers = DriversTableMetadata;

  // Table Actions
  public generalActions = driverService.generalTableActions();
  public rowActions = driverService.rowActions();
  public multiActions = driverService.getMultiActions();

  public paginate = driverService.paginationAction();

  @Getter("drivers", { namespace })
  public drivers!: Driver[] | null;

  @Getter("loading", { namespace })
  public loading!: boolean;

  @Getter("pagination", { namespace })
  public pagination!: TablePagination | null;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  @Action("loadDrivers", { namespace })
  private loadDrivers!: Callback;

  @Action("setPageNav", { namespace: "PageNavModule" })
  private setPageNav!: PageNavAction;

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage
    });
  }

  public filter(header: TableHeader) {
    const h = { ...header };
    if (h.value === "name") {
      h.value = "firstname";
    }
    driverService.sortQuery(h);
  }

  public get driversParsed() {
    return (
      this.drivers &&
      this.drivers.map(driver => ({
        ...driver,
        name: `${driver.firstname} ${driver.lastname}`
      }))
    );
  }

  public get canDeleteDrivers() {
    return this.hasPermission(policyList.deleteDrivers);
  }

  public mounted() {
    this.$changes.watch(pusherEvents.driver, this.loadDrivers);
    this.setPageNav({
      title: "drivers.title",
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    this.loadDrivers();
    this.headers.map(h => {
      h.label = this.$t(h.label).toString();
      return h;
    });
  }
}
